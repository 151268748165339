import React from 'react';
import PropTypes from 'prop-types';
import { Remarkable } from 'remarkable';
import classNames from 'classnames';

const md = new Remarkable({
  html: true,
});

const CmsTableWrapper = ({ children, columns }) => {
  const childrenArray = [];

  children.map((child) => {
    if (typeof child === 'string') {
      childrenArray.push(child);
    } else if (typeof child === 'object' && child.type) {
      childrenArray.push(`<${child.type}>`);
    }
    return childrenArray;
  });

  return (
    <div
      className={classNames(
        'article-table',
        columns && columns !== '' && `article-table--columns-${columns}`
      )}
    >
      <div className="article-table__wrapper">
        <div
          className="article-table__wrapper-inner"
          dangerouslySetInnerHTML={{
            __html: md.render(childrenArray.join('')),
          }}
        />
      </div>
    </div>
  );
};

CmsTableWrapper.propTypes = {
  children: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.shape()])
  ),
  columns: PropTypes.string,
};

export { CmsTableWrapper };
