import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const NavigationNextChapter = ({ nextChapter }) => (
  <div className="ss-next">
    <div className="ss-next__info">{`Next: Chapter ${nextChapter.number}`}</div>
    <Link className="ss-next__link" to={nextChapter.articles[0].slug}>
      {nextChapter.name}
    </Link>
  </div>
);

NavigationNextChapter.propTypes = {
  nextChapter: PropTypes.shape({
    articles: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string.isRequired,
      })
    ),
    number: PropTypes.number,
    name: PropTypes.string,
  }),
};

export { NavigationNextChapter };
