import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classNames from 'classnames';

const BookNavigationArticles = ({ chapterArticles }) => (
  <ul className="ss-nav-articles">
    {chapterArticles.map((article) => (
      <li className="ss-nav-articles__item" key={article.id}>
        <Link
          className={classNames('ss-nav-articles__link', {
            'is-active': article.isActive,
          })}
          to={article.slug}
        >
          {article.frontmatter.title}
        </Link>
      </li>
    ))}
  </ul>
);

BookNavigationArticles.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  chapterArticles: PropTypes.arrayOf(
    PropTypes.shape({
      article: PropTypes.shape({
        id: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
        frontmatter: PropTypes.shape({
          title: PropTypes.string,
        }),
      }),
    })
  ),
};

export { BookNavigationArticles };
