import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { BookNavigationChapters } from './BookNavigationChapters';

const BookNavigation = ({
  chapters,
  isOpenChapterNav,
  toggleBookNav,
  bookNavRef,
}) => {
  const navigationRef = useRef(null);

  return (
    <div
      ref={bookNavRef}
      className={classNames('ss-nav ss-book-nav inverted', {
        open: isOpenChapterNav,
      })}
    >
      <button
        type="button"
        className="btn ss-book-nav__button"
        onClick={toggleBookNav}
      >
        <span className="sr-only">Close navigation</span>
      </button>
      <BookNavigationChapters
        chapters={chapters}
        navigationRef={navigationRef}
      />
    </div>
  );
};

BookNavigation.propTypes = {
  chapters: PropTypes.arrayOf(PropTypes.shape()),
  bookNavRef: PropTypes.shape(),
  isOpenChapterNav: PropTypes.bool.isRequired,
  toggleBookNav: PropTypes.func.isRequired,
};

export { BookNavigation };
