import PropTypes from 'prop-types';
import React from 'react';

import ArrowDown from 'icons/arrow-down.svg';
import { NavigationArticles } from './NavigationArticles';

const Navigation = ({ activeChapter, nextChapter, showNextChapter }) => (
  <div className="ss-nav ss-nav--desktop">
    <a href="#ss-content" className="ss-nav__skip-nav">
      Skip to content (Press Enter)
      <ArrowDown />
    </a>
    <div className="ss-nav__chapter-number">
      {`Chapter ${activeChapter.number}`}
    </div>
    <h3 className="ss-nav__chapter">{activeChapter.name}</h3>
    <NavigationArticles
      activeChapter={activeChapter}
      nextChapter={nextChapter}
      showNextChapter={showNextChapter}
    />
  </div>
);

Navigation.propTypes = {
  activeChapter: PropTypes.shape({
    number: PropTypes.number,
    name: PropTypes.string,
  }),
  nextChapter: PropTypes.shape(),
  showNextChapter: PropTypes.bool,
};

export { Navigation };
