import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ReactPlayer from 'react-player';

import './scss/cms-video-player.scss';
import IconPlayButton from 'icons/play-button.svg';

const CmsVideoPlayer = ({ video, title, config }) => {
  const WISTIA_URL = 'https://home.wistia.com/medias/';
  const videoUrl = WISTIA_URL.concat(video);

  const [hasVideoStarted, setVideoStarted] = useState(false);

  return (
    <div className="cms-video-player">
      <button
        type="button"
        onClick={() => setVideoStarted(true)}
        className={classNames('cms-video-player__play-button', {
          hidden: hasVideoStarted,
        })}
      >
        <IconPlayButton className="cms-video-player__play-icon" />
        <span className="sr-only">{`Play video ${title}`}</span>
      </button>
      <ReactPlayer
        url={videoUrl}
        config={{
          wistia: {
            options: config,
          },
        }}
        onPlay={() => !hasVideoStarted && setVideoStarted(true)}
        playing={hasVideoStarted}
        className="cms-video-player__video"
      />
    </div>
  );
};

CmsVideoPlayer.propTypes = {
  video: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  config: PropTypes.oneOfType([PropTypes.shape()]),
};

export { CmsVideoPlayer };
