import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import rehypeReact from 'rehype-react';

import './scss/styles.scss';
import { Layout } from 'layout';
import { useCloseOnOutsideClick, secretSourceChapters } from 'utilities';
import { CmsVideoPlayer } from 'components/cmsSnippets/cmsVideoPlayer';
import { CmsTableWrapper } from 'components/cmsSnippets/cmsTableWrapper';
import FocusLock from 'react-focus-lock';
import {
  Navigation,
  BookNavigation,
  BookPromoBanner,
  BookNavigationButton,
  Sidebar,
  NavigationNextArticle,
} from './components';
import { bookBanners } from './getBookBanners';

const SecretSourcePages = ({ data, pageContext }) => {
  const { markdownRemark } = data;
  const bookBanner = bookBanners().find(
    (banner) => banner.frontmatter.name === markdownRemark.frontmatter.banner
  ).frontmatter;

  const [sidebarClassName, setSidebarClassName] = useState(
    'ss-page__sidebar sticky'
  );

  const navRef = useRef(null);
  const bookNavRef = useRef(null);

  const [isOpenChapterNav, setChapterNav] = useState(false);
  const toggleBookNav = () => setChapterNav(!isOpenChapterNav);
  useCloseOnOutsideClick(bookNavRef, toggleBookNav, isOpenChapterNav);

  // eslint-disable-next-line new-cap
  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
      'video-player': CmsVideoPlayer,
      'table-wrapper': CmsTableWrapper,
    },
  }).Compiler;

  const chapters = secretSourceChapters(markdownRemark.id);
  const activeChapter = chapters.find((chapter) => chapter.isActive === true);
  const activeArticle = activeChapter.articles.find(
    (article) => article.id === pageContext.id
  );
  let nextChapter;
  let nextArticle =
    activeChapter.articles[activeChapter.articles.indexOf(activeArticle) + 1];
  let showSidebarNav = false;
  let showNextChapter = false;

  if (typeof activeChapter !== 'undefined') {
    showSidebarNav = true;
    nextChapter = chapters[activeChapter.number];
  }

  if (nextChapter) {
    showNextChapter = true;
  }

  if (!nextArticle && nextChapter) {
    nextArticle = nextChapter.articles[0];
  }

  return (
    <Layout
      metaTitle={
        markdownRemark.frontmatter.metaInformation.metaTitle ||
        markdownRemark.frontmatter.title
      }
      metaDescription={
        markdownRemark.frontmatter.metaInformation.metaDescription || null
      }
      metaKeywords={
        markdownRemark.frontmatter.metaInformation.metaKeywords || null
      }
      metaImage={
        markdownRemark.frontmatter.metaInformation.metaImage
          ? markdownRemark.frontmatter.metaInformation.metaImage.children[0]
              .fixed.src
          : null
      }
      type="article"
      className={classNames('have-full-height', {
        'open-book-navigation': isOpenChapterNav,
      })}
    >
      <FocusLock disabled={!isOpenChapterNav}>
        <BookNavigation
          chapters={chapters}
          bookNavRef={bookNavRef}
          toggleBookNav={toggleBookNav}
          isOpenChapterNav={isOpenChapterNav}
        />
      </FocusLock>
      <div className="ss-page">
        <Sidebar
          navRef={navRef}
          setSidebarClassName={setSidebarClassName}
          sidebarClassName={sidebarClassName}
        >
          <BookNavigationButton toggleBookNav={toggleBookNav} />
          {showSidebarNav && (
            <Navigation
              activeChapter={activeChapter}
              nextChapter={nextChapter}
              showNextChapter={showNextChapter}
            />
          )}
        </Sidebar>
        <div className="ss-page__content-column">
          <article className="ss-page__content content" id="ss-content">
            <h1>{markdownRemark.frontmatter.title}</h1>
            {renderAst(markdownRemark.htmlAst)}
            {nextArticle && <NavigationNextArticle nextArticle={nextArticle} />}
          </article>
          <BookPromoBanner
            image={bookBanner.image}
            imageDescription={bookBanner.imageDescription}
            title={bookBanner.title}
            description={bookBanner.description}
            link={bookBanner.link}
            linkText={bookBanner.linkText}
          />
        </div>
      </div>
    </Layout>
  );
};

SecretSourcePages.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        metaInformation: PropTypes.shape({
          metaTitle: PropTypes.string,
          metaDescription: PropTypes.string,
          metaKeywords: PropTypes.arrayOf(PropTypes.string),
          metaImage: PropTypes.oneOfType([PropTypes.object]),
        }),
        title: PropTypes.string,
        banner: PropTypes.string,
      }),
      htmlAst: PropTypes.shape(),
      id: PropTypes.string,
    }),
  }),
  pageContext: PropTypes.shape(),
};

export const secretSourcePagesQuery = graphql`
  query SecretSourcePageArticle($id: String!) {
    markdownRemark(id: { eq: $id }) {
      htmlAst
      id
      tableOfContents(maxDepth: 2)
      frontmatter {
        metaInformation {
          metaTitle
          metaDescription
          metaKeywords
          metaImage {
            children {
              ... on ImageSharp {
                fixed(width: 1080, height: 1080) {
                  src
                }
              }
            }
          }
        }
        title
        banner
      }
      parent {
        ... on File {
          name
          relativeDirectory
          relativePath
          id
        }
      }
    }
  }
`;

export default SecretSourcePages;
