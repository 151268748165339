import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const NavigationNextArticle = ({ nextArticle }) => (
  <div className="ss-next">
    <div className="ss-next__info">Next article</div>
    <Link
      className="ss-next__link"
      to={`/secret-source${nextArticle.fields.slug}`}
    >
      {nextArticle.frontmatter.title}
    </Link>
  </div>
);

NavigationNextArticle.propTypes = {
  nextArticle: PropTypes.shape({
    fields: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }),
    frontmatter: PropTypes.shape({
      title: PropTypes.string,
    }),
  }),
};

export { NavigationNextArticle };
