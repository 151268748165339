import React from 'react';
import PropTypes from 'prop-types';

const BookNavigationButton = ({ toggleBookNav }) => (
  <button type="button" className="ss-nav-btn btn" onClick={toggleBookNav}>
    The book
  </button>
);

BookNavigationButton.propTypes = {
  toggleBookNav: PropTypes.func.isRequired,
};

export { BookNavigationButton };
