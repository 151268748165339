import { graphql, useStaticQuery } from 'gatsby';

export const bookBanners = () => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query SECRET_SOURCE_BANNER {
        allMarkdownRemark(
          filter: { frontmatter: { pageId: { eq: "secretSourceBanner" } } }
        ) {
          edges {
            node {
              id
              frontmatter {
                name
                title
                description
                image {
                  children {
                    ... on ImageSharp {
                      fluid(maxWidth: 335, maxHeight: 387) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
                imageDescription
                link
                linkText
              }
            }
          }
        }
      }
    `
  );
  return allMarkdownRemark.edges.map(({ node }) => node);
};
