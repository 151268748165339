import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import classNames from 'classnames';

import { generateUniqueId } from 'utilities';
import { BookNavigationArticles } from './BookNavigationArticles';

const BookNavigationChapters = ({ navigationRef, chapters }) => (
  <nav className="ss-book-nav-chapters">
    <ul className="ss-book-nav-chapters__list" ref={navigationRef}>
      {chapters.map((chapter) => (
        <li className="ss-book-nav-chapters__item" key={generateUniqueId()}>
          <div className="ss-book-nav-chapters__chapter-number">{`Chapter ${chapter.number}`}</div>
          <Link
            to={chapter.articles[0].slug}
            className={classNames('ss-book-nav-chapters__link', {
              'is-active': chapter.isActive,
            })}
          >
            {chapter.name}
          </Link>
          <BookNavigationArticles chapterArticles={chapter.articles} />
        </li>
      ))}
    </ul>
  </nav>
);

BookNavigationChapters.propTypes = {
  chapters: PropTypes.arrayOf(
    PropTypes.shape({
      articles: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string,
        })
      ),
      number: PropTypes.number,
      name: PropTypes.string,
    })
  ),
  navigationRef: PropTypes.shape({
    current: PropTypes.shape({
      offsetHeight: PropTypes.number,
    }),
  }),
};

export { BookNavigationChapters };
