import React from 'react';
import PropTypes from 'prop-types';

import { Image } from 'components/conditionalImage';
import { CMSLink } from 'components/cmsLink';

const BookPromoBanner = ({
  title,
  description,
  image,
  imageDescription,
  link,
  linkText,
}) => (
  <div className="ss-page__book-promo ss-book-promo">
    <Image
      className="ss-book-promo__image"
      image={image && image.children[0].fluid}
      imageText={imageDescription}
    />
    <h2 className="ss-book-promo__title">{title}</h2>
    <p className="ss-book-promo__description">{description}</p>
    {link && linkText && (
      <CMSLink linkUrl={link} className="ss-book-promo__btn btn">
        {linkText}
      </CMSLink>
    )}
  </div>
);

BookPromoBanner.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object]),
  imageDescription: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
};

export { BookPromoBanner };
