import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'gatsby';
import scrollTo from 'gatsby-plugin-smoothscroll';

import { NavigationNextChapter } from './NavigationNextChapter';

const NavigationArticles = ({
  activeChapter,
  nextChapter,
  showNextChapter,
}) => {
  const chapterArticles = activeChapter.articles;

  useEffect(() => {
    const links = document.querySelectorAll('#article-toc a');

    if (links.length > 0) {
      links.forEach((el) => {
        el.addEventListener('click', (e) => {
          e.preventDefault();
          const anchor = el.href.split('#')[1];
          scrollTo(`#${anchor}`);
        });
      });
    }
  });

  return (
    <nav>
      {chapterArticles && chapterArticles.length > 0 && (
        <ul className="ss-nav-articles">
          {chapterArticles.map((article) => (
            <li
              className={classNames('ss-nav-articles__item', {
                'is-active': article.isActive,
              })}
              key={article.id}
            >
              <Link className="ss-nav-articles__link" to={article.slug}>
                {article.frontmatter.title}
              </Link>

              {article.isActive && (
                <div
                  className="ss-nav-articles__anchors"
                  id="article-toc"
                  dangerouslySetInnerHTML={{
                    __html: article.tableOfContents,
                  }}
                />
              )}
            </li>
          ))}
        </ul>
      )}
      {showNextChapter && <NavigationNextChapter nextChapter={nextChapter} />}
    </nav>
  );
};

NavigationArticles.propTypes = {
  activeChapter: PropTypes.shape({
    articles: PropTypes.arrayOf(PropTypes.shape()),
  }),
  nextChapter: PropTypes.shape(),
  showNextChapter: PropTypes.bool,
};

export { NavigationArticles };
