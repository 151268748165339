import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Sidebar = ({
  navRef,
  children,
  sidebarClassName,
  setSidebarClassName,
}) => {
  const [height, setHeight] = useState(window.innerHeight);

  const handleResize = useCallback(() => {
    setHeight(window.innerHeight);

    if (height < navRef.current.clientHeight) {
      setSidebarClassName('ss-page__sidebar');
    } else {
      setSidebarClassName('ss-page__sidebar sticky');
    }
  }, [navRef, height]);

  useEffect(() => {
    if (navRef) {
      handleResize();
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <aside ref={navRef} className={sidebarClassName}>
      {children}
    </aside>
  );
};

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
  setSidebarClassName: PropTypes.func.isRequired,
  sidebarClassName: PropTypes.string.isRequired,
  navRef: PropTypes.shape({
    current: PropTypes.shape({
      className: PropTypes.string,
      clientHeight: PropTypes.number,
    }),
  }),
};

export { Sidebar };
